@import '~www/themes/doctors/common/variables';

.b-top-card {
  width: 85vw;
  height: 135px;
  box-sizing: border-box;
  margin: 0 0 0 16px;
  padding: 20px 16px;
  display: flex;
  position: relative;
  font-family: $font-family-regular;
  font-style: normal;

  &__place {
    width: 27px;
    height: 27px;
    position: absolute;
    top: 4px;
    left: 2px;
    z-index: 1;
    background: url('/static/_v1/pb/icons/top-place-author-star.svg') center no-repeat;
    background-size: cover;
    font-weight: $weight-medium;
    font-size: $font-size-caption;
    line-height: 27px;
    text-align: center;
    color: white;
  }

  &__photo-wrap {
    width: 58px;
    height: 58px;
    margin: 0 16px 5px 0;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
  }

  &__photo {
    width: 100%;
  }

  &__count {
    font-weight: $weight-regular;
    font-size: $font-size-caption;
    line-height: 14px;
    color: $color-dark-grey;
  }

  &__title {
    font-weight: $weight-medium;
    font-size: $font-size-body-1;
    line-height: 19px;
    color: $ui-kit-primary;
  }

  &__link {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: inherit;
    }
  }

  &__subtitle {
    margin: 0 0 7px;
    font-weight: $weight-regular;
    font-size: $font-size-body-2;
    line-height: 16px;
    color: $ui-kit-text-secondary;
  }

  &__summary {
    height: 42px;
    overflow: hidden;
    font-weight: $weight-regular;
    font-size: $font-size-caption;
    line-height: 14px;
    color: $color-dark-grey;
  }

  &__ellip-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  &__ellip-line {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
    position: relative;
    overflow: hidden;
  }
}
