@import '~www/themes/doctors/common/variables';

%owl-arrow {
  width: 53px;
  height: 53px;
  background: url('/static/_v1/pd/icons/slider-arrow.svg') no-repeat center;
  border: 1px solid $color-ultra-deep-grey;
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: 0 15px 20px rgba(151, 158, 167, 0.1);
  position: absolute;
  top: 40%;
  transition: 0.4s;
}

.b-owl-slider {
  &.owl-carousel {
    touch-action: pan-y;
  }

  .owl-stage-outer {
    padding: 0 0 24px;
  }

  .owl-stage {
    display: flex;
    flex-flow: row nowrap;
  }

  .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .owl-nav {
    .owl-prev {
      @extend %owl-arrow;

      left: -60px;

      &:hover {
        transform: translateX(-3px);
      }
    }

    .owl-next {
      @extend %owl-arrow;

      transform: rotateY(180deg);
      right: -60px;

      &:hover {
        transform: translateX(3px) rotateY(180deg);
      }
    }
  }

  .owl-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 11px;
    background: $color-deep-soft-blue;
    transition: 0.2s ease all;

    &.active {
      background: $ui-kit-primary;
    }

    &.aside {
      width: 5px;
      height: 5px;
      margin: 0 4px;
    }

    &.aside_small {
      width: 3px;
      height: 3px;
      margin: 0 4px;
    }

    &.aside_none {
      display: none;
    }

    &.perm_none {
      display: none;
    }
  }

  &_no-stage-padding {
    .owl-stage-outer {
      padding: 0;
    }
  }
}
