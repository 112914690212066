.list {
  list-style-type: none;
  padding-left: 0;
}

.list_full-width {
  margin: 0;
}

.list__item {
  position: relative;
}

.list__text {
  color: #333333;
}

.list__link-author-wrap {
  display: inline;
  vertical-align: top;
}

.list__link-description {
  white-space: normal;
  clear: both;
  margin-top: 0;
}

.list__link_shine {
  background-color: #3388CC !important;
  box-shadow: 0 0 12px #3388CC !important;
  border-color: #3388CC !important;
  color: white !important;
  z-index: 1;
}

.list__item_solo {
  padding: 10px 15px 10px 90px;
  position: relative;
}

.list__item_white {
  background-color: white;
}

.list__item-fasovka {
  text-align: left;
  border: 1px solid #DDDDDD;
  padding: 10px 20px;
}

.list__item-print {
  color: #3388CC;
}

.list__item-print:visited {
  color: #3388CC;
}

.list__item-fasovka > p {
  margin: 0;
}

.list__link-classic > p > a {
  color: #3388CC;
}

.list__item-wrapp-black > li > a:visited {
  color: #333333;
}

.list__link_black {
  color: #333333;
  font-weight: 400;
}

.list__link_black:visited {
  color: #333333 !important;
}

.list__link_relative {
  position: relative;
}

.list__link {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  display: block;
  text-decoration: none;
  color: #0054B9;
  font-weight: 500;
  width: 90%;
  text-align: left;
  margin: 0 auto;
  padding: 13.38px 16px 12px 11px;
  font-size: 16px;
  border: 1px solid #DDDDDD;
  border-bottom: none;
  background-color: white;
}

.list__link:not(.list__link_thin, .list__link_full):visited {
  color: #0054B9;
}

.list__link_ser {
  color: #999999;
}

.list__title {
  margin: 0;
}

.list__title__wrap {
  margin: 20px 0 5px;
}

.list__title_center {
  margin-top: 10px;
  color: #0054B9;
}

.list__title_full-width {
  position: relative;
  margin: 0 -3.1%;
  height: 28px;
  padding-left: 46px;
  padding-top: 12px;
  font-weight: 400;
  border: 1px solid #DDDDDD;
  border-left: none;
  border-right: none;
  font-size: 17px;
}

.list__title_full-width-r::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 18px;
  display: block;
  background-color: #A5A5A5;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20style%3D%22fill%3A%23FFFFFF%3B%22%20points%3D%223.404%2C2.051%208.354%2C7%203.404%2C11.95%205.525%2C14.07%2012.596%2C7%205.525%2C-0.071%20%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.list__title_full-width-d::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 18px;
  display: block;
  background-color: #A5A5A5;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20style%3D%22fill%3A%23FFFFFF%3B%22%20points%3D%223.404%2C2.051%208.354%2C7%203.404%2C11.95%205.525%2C14.07%2012.596%2C7%205.525%2C-0.071%20%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(90deg);
}

.list__link_title {
  background: #E9E9E9;
  padding: 10px 14px;
  color: #333333;
  width: 90%;
  margin: 0 auto;
  border: 1px solid #DDDDDD;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.list__item_full-width {
  margin-left: -3%;
  margin-right: -3%;
}

.list__item_full-width-with-img {
  width: calc(100% - 106px);
}

.list__link-classic {
  font-size: 14px;
  color: #333333;
  font-weight: normal;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
}

.list__link_back {
  background-color: white;
  padding-left: 40px;
  display: inline-block;
  width: calc(100% - 50px) !important;
  position: absolute;
  top: -15px;
}

.list__item_back {
  position: relative;
  height: 40px;
}

.list__link_back::after {
  content: '';
  position: absolute;
  left: 10px;
  top: 10px;
  display: block;
  background-color: #A5A5A5;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20style%3D%22fill%3A%23FFFFFF%3B%22%20points%3D%223.404%2C2.051%208.354%2C7%203.404%2C11.95%205.525%2C14.07%2012.596%2C7%205.525%2C-0.071%20%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}

.list__link_back-auctions::after {
  top: 50%;
}

.list__link_full {
  width: calc(100% - 27px);
  color: #333333;
  display: inline-block;
  font-weight: 400;
  position: relative;
  border-radius: 0 !important;
  border-left: none;
  border-right: none;
  margin: 0;
}

.list__link_full-img {
  min-height: 50px;
  padding-left: 90px;
  width: calc(100% - 101px);
}

.list > .list__item:first-child > .list__link_full {
  border-top: none;
}

.list__count-pharm {
  position: absolute;
  right: 44px;
  top: 11px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #DDDDDD;
  font-weight: 500;
  font-size: 13px;
  padding: 2px 3px;
  color: #333333;
}

.list__link_full-background::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  background-color: #A5A5A5;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20style%3D%22fill%3A%23FFFFFF%3B%22%20points%3D%223.404%2C2.051%208.354%2C7%203.404%2C11.95%205.525%2C14.07%2012.596%2C7%205.525%2C-0.071%20%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.list__link_thin {
  font-weight: 400;
}

.list__link_background {
  position: relative;
  margin: 0;
  width: calc(100% - 27px);
}

.list__link_blue {
  color: #0054B9;
}

.list__link_icon-next::after {
  content: '';
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
  display: block;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: url('/static/_v1/pd/icons/arrows/drop-right-bold.png') no-repeat right 5px center;
  background-size: 16px;
}

.list__link_background::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  background-color: #A5A5A5;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20style%3D%22fill%3A%23FFFFFF%3B%22%20points%3D%223.404%2C2.051%208.354%2C7%203.404%2C11.95%205.525%2C14.07%2012.596%2C7%205.525%2C-0.071%20%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.list__link_click {
  background-color: #3388CC !important;
  color: #0354B7 !important;
  border: none;
}

.list__link_color {
  color: #3388CC;
}

.list__link_shadow {
  box-shadow: 0 0 12px #3388CC;
  background-color: #EDEDED;
}

.list > .list__item:first-child > .list__link {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.list__link-with-img {
  padding: 13px 16px 10px 90px;
  width: calc(100% - 106px);
  position: relative;
  box-shadow: none;
  min-height: 60px;
}

.list > .list__item:last-child > .list__link:not(.list__link-with-img) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid #C7D1DF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.list > .list__item:first-child > .list__link-with-img {
  border-top: 1px solid #DDDDDD;
}

.list__link-count {
  position: absolute;
  right: 16px;
  background-color: white;
  top: 12px;
  padding: 3px 5px;
  border: 1px solid #EDEDED;
  border-radius: 3px;
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}

.list__link_thin-black {
  color: #333333;
}

.list__find-town_hidden {
  display: none;
}

.list__link-avatar {
  position: absolute;
  left: 4px;
  top: 1px;
  width: 80px;
}

.list__link-avatar_top {
  top: 6px;
}

.list__link-img {
  width: 80px;
  position: absolute;
  left: 5px;
  top: 5px;
}

.list__link-avatar-lpu {
  position: absolute;
  left: 17px;
  top: 30px;
  width: 50px;
}

.list__link-drug {
  position: relative;
  padding-left: 90px;
  width: calc(100% - 101px);
  min-height: 50px;
}

.list__link-drug-img {
  position: absolute;
  top: -2px;
  left: 0;
  width: 80px;
}

.list__link-title {
  margin: 0;
  color: #0054B9;
  font-size: 17px;
}

.list__link-title_bold {
  font-weight: 500;
}

.list__link-rates-count {
  color: #858585;
  font-size: 14px;
  position: absolute;
  top: 3px;
  right: -75px;
}

.list__link-price {
  color: red;
  font-size: 17px;
  font-weight: normal;
}

.list__link-rates {
  background-color: white;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  text-align: left;
}

.list__link-rates::before {
  display: inline-block;
  width: 10px;
  content: '\00ab ';
}

.list__link-rates::after {
  display: inline-block;
  width: 10px;
  content: ' \00bb';
}

/*  в будущем необходимо сделать общие классы для таких случаев( в данный момент висит ещё окружение Jquery.mobile ) */

.list__doctors {
  list-style-type: none;
  padding-left: 0;
  margin: 20px 0 0;
}

.list__doctors_up {
  margin-top: 5px;
}

.list__doctors-title {
  border-bottom: 1px solid #DDDDDD;
  font-size: 1.4em;
  margin-top: 20px;
  margin-left: -0.5em;
  margin-bottom: 5px;
  width: calc(100% + 1em);
  line-height: 25px;
}

.list__doctors-title_small {
  margin-top: 0;
}

.list__doctors-link {
  border-left: none;
  border-radius: 0 !important;
  border-right: none;
  color: #0054B9 !important;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  position: relative;
  padding-top: 10px;
  height: 100%;
  min-height: 30px;
  padding-left: 35px;
  width: calc(100% - 35px);
  white-space: normal;
}

.list__doctors-link_first {
  font-size: 1.3em;
  padding-left: 26px;
  width: calc(100% - 102px) !important;
}

.list__doctors-link_second {
  height: 27px;
  padding-top: 5px;
}

.list__doctors-count_first {
  top: calc(50% - 8px) !important;
}

.list__doctors-count_second {
  top: 6px !important;
}

.list__doctors-link_slim {
  padding-right: 75px;
  width: calc(100% - 110px);
}

.list__doctors-item {
  margin-left: -1em;
  margin-right: -1em;
  background-color: white;
}

.list__doctors-item_nojqm {
  margin-left: -10px;
  margin-right: -10px;
  text-align: left;
}

.list__doctors-item-border {
  display: block;
  height: 1px;
  background-color: #DDDDDD;
  margin: 0 auto;
  width: 86%;
}

.list__doctors-count {
  position: absolute;
  display: inline-block;
  right: 50px;
  font-size: 13px;
  top: 10px;
  color: #777777;
  font-weight: 500;
  background: white;
  border-radius: 3px;
  padding: 2px 3px 0;
}

.list__doctors-link_black {
  height: 100%;
  color: black !important;
  padding-top: 5px;
  padding-right: 45px;
  width: calc(100% - 80px);
}

.list__doctors-link_black-min {
  padding-top: 10px;
}

.list__doctors-link-black-arrow::after {
  content: '';
  position: absolute;
  right: 5%;
  top: 17px;
  display: block;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: url('/static/_v1/pd/icons/arrows/drop-right-bold.png') no-repeat right 5px center;
  background-size: 16px;
}

.list__doctors-link-black-arrow_min::after {
  top: 7px;
}

.list__doctors-link_shine-on {
  border: none;
  background: #0097D8 !important;
  color: white !important;
  text-shadow: 0 0 0;
  z-index: 10;
  box-shadow: 0 0 10px 1px #0097D8;
}

.list__doctors > li:nth-child(1) > :nth-child(1) {
  display: none;
}

.list__doctors-link-title {
  font-size: 17px;
  font-weight: 500;
}

.list__doctors-count_flow {
  position: initial;
  margin-left: 20px;
  color: #333333;
  background: none;
  font-size: 15px;
}

.list__doctors-link-disc {
  font-size: 15px;
  color: #777777;
  display: inline-block;
  padding: 2px 4px 4px 0;
  border-radius: 3px;
  margin-bottom: 5px;
}

.list__doctors-link-disc_white {
  color: #FBFBFB;
}

.list__item_auctions {
  position: relative;
  width: 90%;
  text-align: left;
  margin: 0 auto;
  padding: 13.38px 11px 12px 16px;
  font-size: 16px;
  border: 1px solid #DDDDDD;
  border-bottom: none;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  display: block;
}
