@import '~www/themes/doctors/common/variables';

%nav-arrow {
  width: 53px;
  height: 53px;
  background: url('/static/_v1/pd/icons/slider-arrow.svg') no-repeat center;
  border: 1px solid $color-ultra-deep-grey;
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: 0 15px 20px rgba(151, 158, 167, 0.1);
  top: calc(50% - 26px);
  transition: 0.4s;
  outline: none;

  &:hover {
    border-color: $ui-kit-text-secondary;
  }
}

.b-popup-gallery {
  &__popup {
    .mfp-container {
      position: static;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .mfp-content {
      margin: 0 24px;
      width: 750px;
      order: 2;
    }

    .b-popup-gallery__wrapper {
      position: relative;
      border-radius: 5px;
      overflow: hidden;

      &_hidden-title .mfp-bottom-bar {
        display: none;
      }

      .mfp-bottom-bar {
        width: 100%;
        margin: 0;
        padding: 0;
        position: static;
      }

      .mfp-title {
        background: $ui-kit-bg-gray-0;
        padding: 24px;
        font-family: $font-family-regular;
        font-style: normal;
        font-weight: $weight-regular;
        font-size: $font-size-body-1;
        line-height: $line-height-base;
        color: $color-dark-grey;
      }

      .mfp-img {
        width: 100%;
        padding: 0;
      }

      .mfp-close {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        background: rgba(24, 29, 33, 0.5);
        border-radius: 100%;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        transition: background-color ease-in-out 0.2s;

        &:hover {
          transition: background-color ease-in-out 0.2s;
          background: $color-neur-dark;
        }

        @mixin stick {
          content: '';
          position: absolute;
          height: 15px;
          width: 1px;
          left: 20px;
          top: 13px;
          background: $ui-kit-bg-gray-0;
          border-radius: 1px;
        }

        &::before {
          @include stick;

          transform: rotateZ(45deg);
        }

        &::after {
          @include stick;

          transform: rotateZ(-45deg);
        }
      }
    }

    .mfp-arrow-left {
      @extend %nav-arrow;

      order: 1;

      &:hover {
        transform: translateX(-3px);
      }
    }

    .mfp-arrow-right {
      @extend %nav-arrow;

      transform: rotateY(180deg);
      order: 3;

      &:hover {
        transform: translateX(3px) rotateY(180deg);
      }
    }

    .mfp-preloader {
      order: 2;
      position: static;
      margin: 24px;
    }

    .ellip-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
    }

    .ellip-line {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      max-width: 100%;
      position: relative;
      overflow: hidden;
    }
  }
}
