.paginator {
  max-height: 50px;
  padding: 0 15px;
  padding-top: 10px;
}

.paginator__btn {
  display: inline-block;
  border: 1px solid #CDCDCD;
  height: 28px;
  width: 75px;
  text-decoration: none;
  text-align: center;
  padding-top: 12px;
  background-color: white;
  color: #367FCC;
  font-size: 16px;
}

.paginator__btn_min {
  text-align: center;
  color: #367FCC;
  font-size: 14px;
}

.paginator__btn_back {
  float: left;
}

.paginator__btn_next {
  float: right;
  width: 140px;
}
