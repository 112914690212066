@import '~www/themes/doctors/common/variables';

.b-section {
  font-family: $font-family-regular;
  font-style: normal;
  margin: 40px 0;

  &__body {
    &_row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }

  &__subheader {
    font-weight: $weight-medium;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;
  }

  &__header {
    margin: 0 0 24px;

    &_unwraped {
      padding: 0 16px;
    }
  }

  &__title {
    font-weight: $weight-medium;
    font-size: 20px;
    line-height: 23px;
    color: $color-neur-dark;
  }

  &__more-link {
    color: $ui-kit-primary;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: $ui-kit-primary;
    }
  }

  &__btn {
    margin: 24px 16px 32px;
    padding: 14px 0;
    background: #ECF1FB;
    border-radius: 5px;
    font-weight: $weight-medium;
    font-size: $font-size-body-2;
    line-height: $line-height-base;
    color: $ui-kit-primary;
    text-decoration: none;
    text-align: center;
  }
}
