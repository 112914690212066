@import '~www/themes/doctors/common/variables';

.b-club-list {
  counter-reset: clublist;

  &__item {
    display: flex;
    align-items: center;

    &::before {
      counter-increment: clublist;
      content: counter(clublist);
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      background-color: $ui-kit-bg-club;
      color: $ui-kit-text-error;
      border-radius: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
