@import '~www/themes/doctors/common/variables';

.b-intro {
  background: $ui-kit-bg-gray-0;
  padding: 40px 0;
  font-family: $font-family-regular;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.03);

  &__header {
    text-align: center;
  }

  &__title {
    color: $color-neur-dark;
    font-size: $font-size-xl;
    font-weight: $weight-medium;

    &_small {
      margin: 0 0 10px;
      font-size: 20px;
      font-weight: $weight-medium;
      line-height: 23px;
    }
  }

  &__search-container {
    margin-top: 24px;
  }

  &__search-wrapper {
    transform: translateY(50%);
    margin-bottom: 24px;
    position: relative;
  }

  &__subtitle {
    color: $ui-kit-text-secondary;
    font-size: $font-size-body-2;
    line-height: 1.3em;

    &_small {
      font-weight: $weight-regular;
      font-size: 13px;
      line-height: 15px;
    }
  }

  &_padding_compact {
    padding: 20px 0 0;
  }

  &__stat {
    margin-top: 16px;

    &_centred {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__stat-reviews-item {
    background: $color-ultra-light-blue;
    text-align: center;
    padding: 15px 8px;
    border-radius: 5px;
  }

  &__stat-reviews-item-num {
    font-size: $font-size-xxl;
    color: $color-neur-dark;
    font-weight: $weight-medium;
  }

  &__stat-reviews-item-name {
    color: $ui-kit-text-secondary;
    font-size: $font-size-body-1;
  }

  &__stats-container {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
  }

  &__stat-item {
    flex: 0 0 50%;
    margin: 12px 0;
  }

  &__stat-item-img {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 27px auto;

    &_type {
      &_reviews {
        background-image: url('/static/_v1/pd/icons/advantages/review.svg');
      }

      &_appointment {
        background-image: url('/static/_v1/pd/icons/advantages/appointment.svg');
      }

      &_doctor {
        background-image: url('/static/_v1/pd/icons/advantages/doctor.svg');
      }

      &_lpu {
        background-image: url('/static/_v1/pd/icons/advantages/lpu.svg');
      }

      &_users {
        background-image: url('/static/_v1/pd/icons/advantages/group-users.svg');
      }
    }
  }

  &__stat-item-content {
    display: inline-block;
    vertical-align: middle;
    padding-left: 11px;
    padding-right: 24px;
    box-sizing: border-box;
    width: calc(100% - 40px);

    &_centred {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__stat-item-num {
    color: $color-neur-dark;
    font-size: $font-size-lg;
    font-weight: $weight-medium;

    &_font-xl {
      font-size: $font-size-xl;
    }
  }

  &__stat-item-name {
    color: $ui-kit-text-secondary;
    font-size: $font-size-body-1;
  }
}
