@import '~www/themes/doctors/common/variables';

.b-categories {
  display: flex;
  flex-flow: column wrap;
  font-family: $font-family-regular;
  font-style: normal;

  &__summary {
    width: 100%;
    margin: 8px 0;
    padding: 16px 0;
    border-radius: 5px;
    font-weight: $weight-medium;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    text-align: center;
    color: $color-dark-grey;
  }

  &__item {
    padding: 8px 0;
    display: flex;
    align-items: center;
    font-weight: $weight-medium;
    font-size: $font-size-body-1;
    text-decoration: none;
    color: $ui-kit-primary;
  }

  &__num {
    width: 32px;
    margin: 0 16px 0 0;
    padding: 7px 0;
    border-radius: 5px;
    background-color: $color-ultra-deep-grey;
    font-weight: $weight-medium;
    font-size: $font-size-body-2;
    line-height: $line-height-base;
    text-align: center;
    color: $color-dark-grey;
  }
}
